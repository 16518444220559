@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.card {
    width: var(--cardWidth);
    min-height: var(--cardHeight);
    padding: 0;
    background-color: var(--color-gray-06);

    --cardWidth: 200px;
    --cardHeight: 320px;

    a:hover,
    a:active,
    a:visited,
    a {
        display: grid;
        grid-template-rows: 44px var(--cardWidth) auto;
        height: 100%;
        text-decoration: none;
    }

    @media (width <= $screen-size-sm) {
        --cardWidth: 160px;
        --cardHeight: 280px;
    }

    &:hover,
    &:focus-within {
        .footerText * {
            color: var(--color-primary-black);
        }
    }

    &:focus-within {
        > * {
            border: none;
            outline: none;
        }
    }
}

.card:hover .overlay,
.card:focus-within .overlay {
    transform: translateY(0);
}

.footer {
    position: relative;
    padding: var(--distance-extra-small);
    overflow: hidden;
    color: var(--color-primary-white);
    background-color: var(--color-gray-06);
}

.footerText {
    position: relative;
    z-index: 1;

    & * {
        color: var(--color-primary-white);
    }
}

.header {
    padding: var(--distance-extra-small);
    color: var(--color-primary-white);
    background-color: var(--color-gray-06);
}

.headerItem {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
}

.rating {
    > span:first-child {
        margin-right: 7px;
    }
}

.icons {
    span:nth-child(even) {
        @media screen and (width >= 855px) {
            margin-right: 10px;
            margin-left: 10px;
        }
    }

    span:last-child {
        @media screen and (width >= 855px) {
            margin-right: 0;
        }
    }
}

.price {
    position: absolute;
    top: 0;
    right: 0;
    padding: spacing(1, 2);
    background-color: var(--color-secondary-yellow);
}

.badge {
    position: absolute;
    max-width: 100%;

    &.primary {
        top: 0;
        right: 0;
    }

    &.secondary {
        right: 0;
        bottom: 0;
    }

    &.topList {
        top: 0;
        left: 0;
    }
}

.cover {
    position: relative;
    display: flex;
    align-items: center;
    justify-self: center;
    width: 100%;
    padding: var(--distance-extra-small);
    overflow: hidden;
    background-color: var(--color-gray-05);

    svg {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media screen and (width >= 768px) {
        padding: 1rem;
    }
}

.image {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overlay {
    position: absolute;
    inset: auto 0 0;
    height: 100%;
    background-color: var(--color-primary-purple);
    transition: transform 0.2s cubic-bezier(1, 0, 0, 1);
    transition-delay: 10ms;
    transform: translateY(101%);
    will-change: transform;
}
